export class BlockGallery {

    glightbox = null;

    _dom = {
        block: null,
        wrapper: null,
        btnClear: null,
        btnMore: null,
    };

    _state = {};

    constructor(block, GLightbox) {
        this._dom.block = block;
        this._dom.wrapper = block.querySelector('.b-gallery__wrapper');
        this._dom.btnClear = block.querySelector('.b-gallery__buttons__btn.clear');
        this._dom.btnMore = block.querySelector('.b-gallery__buttons__btn.more');
        this.glightbox = GLightbox({
            selector: '.b-gallery__img',
            touchNavigation: false,
            loop: false,
            draggable: false
        });
        this.initListeners();
        this.render();
    }

    /**
     * Display and set element
     *
     */
    render() {
        this._dom.block.classList.add('-initialiazed');
    }

    /**
     * On click attach evens
     */
    initListeners() {
        if(this._dom.btnMore) this._dom.btnMore.addEventListener("click", (e) => this.handlerClickEventMore(e));
        if(this._dom.btnClear) this._dom.btnClear.addEventListener("click", (e) => this.handlerClickEventClear(e));
    }

    /**
     * Handler click event in arrows
     *
     * @param e
     */
    handlerClickEventMore(e) {
        const id = this._dom.block.dataset.id;
        const array = window[id];
        array.forEach((elem, index) => {
            const node = this._dom.wrapper.querySelector(`[data-key="${index}"]`);
            if(!node) this.addItem(elem, index);
            else node.classList.remove('--none', '--out')
        })
        this.glightbox.reload();
        this._dom.btnMore.classList.add('--none')
        this._dom.btnClear.classList.remove('--none')
    }

    handlerClickEventClear(e) {
        const id = this._dom.block.dataset.id;
        const array = window[id];
        array.forEach((elem, index) => {
            if(index > 4) {
                const node = this._dom.wrapper.querySelector(`[data-key="${index}"]`);
                node.classList.add('--none');
            }
        })
        this.glightbox.reload();
        this._dom.btnClear.classList.add('--none')
        this._dom.btnMore.classList.remove('--none')
    }

    /**
     * Handler click event in arrows
     *
     * @param e
     */
    addItem(item, index) {
        const node = document.createElement('a');
        node.setAttribute("data-key", index);
        node.setAttribute("href", item.image.url);
        node.setAttribute("class", `b-gallery__img --type-${item.dimension}`);
        node.innerHTML = `<img class="glightbox-single" src="${item.image.url}" alt="${item.image.alt}" />`;
        this._dom.wrapper.appendChild(node);
    }
}

/**
 * Initialize block gallery
 */
export const initBlockGallery = (GLightbox) => {
    [...document.querySelectorAll('.b-gallery:not(.-initialiazed)')].forEach(item => new BlockGallery(item, GLightbox));
};