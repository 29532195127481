export class BlockEvents {

    _dom = {
        block: null,
        btn: null,
        closeBtn: null,
        overlay: null,
        form: null,
    };

    _state = {
        loading: null,
    };

    constructor(block) {
        this._dom.block = block;
        this._dom.btn = block.querySelector('.b-events__info .--button');
        this._dom.btncloseBtn = block.querySelector('.b-events__modal__close');
        this._dom.overlay = block.querySelector('.b-events__modal__overlay');
        this._dom.form = block.querySelector('.wpcf7');
        this.initListeners();
        this.render();
    }

    /**
     * Display and set element
     *
     */
    render() {
        this._dom.block.classList.add('-initialiazed');
    }

    /**
     * On click attach evens
     */
    initListeners() {
        if(this._dom.btn) this._dom.btn.addEventListener("click", (e) => this.handlerClickOpen(e));
        if(this._dom.btncloseBtn) this._dom.btncloseBtn.addEventListener("click", (e) => this.handlerClickClose(e));
        if(this._dom.overlay) this._dom.overlay.addEventListener("click", (e) => this.handlerClickClose(e));
        if(this._dom.form) this._dom.form.addEventListener('wpcf7submit', (e) => this.handlerClickClose(e), false);
    }

    /**
     * Handler click event in button
     *
     * @param e
     */
    handlerClickOpen(e) {
        document.documentElement.style = "overflow:hidden";
        this._dom.block.classList.add("active")
    }

    /**
     * Handler click close elements
     *
     * @param e
     */
    handlerClickClose(e) {
        document.documentElement.style = "";
        this._dom.block.classList.remove("active")
    }
}

/**
 * Initialize block events
 */
export const initBlockEvents = () => {
    [...document.querySelectorAll('.b-events:not(.-initialiazed)')].forEach(item => new BlockEvents(item));
};