export class BlockStoreLocator {

    monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "August", "September", "October", "November", "December"];

    _dom = {
        block: null,
        wrapper: null,
        btn: null,
    };

    _state = {};

    constructor(block) {
        this._dom.block = block;
        this._dom.wrapper = block.querySelector('.b-store_locator__addresses');
        this._dom.btn = block.querySelector('.b-store_locator__button');
        this.initListeners();
        this.render();
    }

    /**
     * Display and set element
     *
     */
    render() {
        this._dom.block.classList.add('-initialiazed');
    }

    /**
     * On click attach evens
     */
    initListeners() {
        if(this._dom.btn) this._dom.btn.addEventListener("click", (e) => this.handlerClickEvent(e));
    }

    /**
     * Handler click event in arrows
     *
     * @param e
     */
    handlerClickEvent(e) {
        const id = this._dom.block.dataset.id;
        const array = window[id];
        array.forEach((elem, index) => {
            if(!this._dom.wrapper.querySelector(`[data-key="${index}"]`)) 
                this.addItem(elem,index);
        })
        this._dom.btn.setAttribute("disabled", "disabled")
        this._dom.btn.setAttribute("style", "opacity:0;")
    }

    /**
     * Handler click event in arrows
     *
     * @param e
     */
    addItem(item, index) {
        const node = document.createElement('div');
        node.setAttribute("data-key", index);
        node.setAttribute("class", `b-store_locator__addresses__item`);
        const dt  = new Date(item.date);
        const day = dt.getDate().toString().padStart(2, '0');
        const month = dt.toLocaleString("default", { month: "short" });
        const year = dt.getFullYear().toString().substr(-2);
        node.innerHTML = `<div class="b-store_locator__addresses__calendar">
            <span class="--day">
                ${day}
            </span>
            <span class="--month">
                ${month} ${year}
            </span>
        </div>
        <div class="b-store_locator__addresses__info">
            <span class="--title">
                ${item.title}
            </span>
            <span class="--address">
                <i class="belliscrepes-icon-map"></i>
                ${item.address}
            </span>
            <span class="--schedules">
                <i class="belliscrepes-icon-calendar"></i>
                ${item.time}
            </span>
        </div>`;
        this._dom.wrapper.appendChild(node);
    }
}

/**
 * Initialize block gallery
 */
export const initBlockStoreLocator = () => {
    [...document.querySelectorAll('.b-store_locator:not(.-initialiazed)')].forEach(item => new BlockStoreLocator(item));
};